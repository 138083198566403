<template>
  <div class="new">
    <div class="overlay"></div>
    <h1>
      Медицинская клиника
    </h1>
    <p>
      Рады пригласить вас к нам!
    </p>
    <div class="knop">
      <a
        href="https://api.whatsapp.com/send?phone=79697598898"
        target="_blank"
        rel="noopener"
      >
        <img class='vk2' alt="" src="../assets/wal.png" />
      </a>
    
    	</div>
    <div class="phone">
      <a class="tel" href="tel:+79697598898">+7 9697598898</a>
    </div>
    <a href="http://novakl.tilda.ws/">
      <button>Перейти в клинику</button>
    </a>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.new {
  height: 100vh;
  background-image: url("../assets/nk.jpg");
  background-size: cover;
  background-position-y: 10%;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3); /* Настройка цвета и прозрачности для блока с заголовком */
  text-align: center;
}
h1 {
  position: absolute;
  width: 100%;
  left: 0;
  top: 30%; /* Отступ сверху */
  font-size: 4.5vw; /* Размер текста заголовка */
  text-align: center;
  text-transform: uppercase; /* Заглавные буквы */
  color: white; /* Цвет заголовка */
  z-index: 1;
}
p {
  position: absolute;
  width: 100%;
  left: 0;
  top: 40%; /* Отступ сверху */
  font-size: 4vw; /* Размер текста заголовка */
  text-align: center;
  color: white; /* Цвет заголовка */
  z-index: 1;
}
.phone {
  position: absolute;
  left: 38%;
  top: 60%;
}
.tel {
  color: white;
  font-size: 3vw;
  text-decoration: none;
}
button {
  background-color: #9369B2;
  border: #9369B2;
  position: absolute;
  left: 39%;
  top: 80%;
  width: 20vw;
  height: 10vh;
  color: white;
  z-index: 1;
  font-size: 2vw;
  cursor: pointer;
  border-radius: 7px;
  transition: all 0.4s ease;
}
.knop {
  position: absolute;
  left: 48%;
  top: 70%;
  z-index: 1;
}
.vk {
  width: 3vw;
  transition: all 0.4s ease;
  z-index: 1;
}
.vk2 {
  width: 3.2vw;
  transition: all 0.4s ease;
  z-index: 1;
}

.vk:hover, .vk2:hover, button:hover {
  transform: scale(1.1);
  transition: all 0.4s ease;
}

@media (max-width: 767px) {

  .new {
    background-position-y: 75%;
    background-position-x: 50%;
  }
  h1 {
    font-size: 40px;
    top: 20%;
  }
  p {
    font-size: 28px;
    top: 35%;
  }
  button {
    width: 300px;
    height: 70px;
    font-size: 20px;
    left: 12%;
  }
  .phone {
    left: 12%;
    top: 48%;
  }
  .tel {
    font-size: 40px;
  }
  .knop {
    left: 7%;
    top: 61%;
    width: 87%;
    display: flex;
    justify-content: center;
  }
  .vk {
    width: 55px;
  }
  .vk2 {
    width: 57px;
  }
}

</style>